import React, { JSX, useEffect, useState } from 'react';
import MultiValuesSelectDropdown from '../Tools/Selects/MultiValuesSelectDropdown';
import { defaultSize } from '../../hooks/datarefs';
import { Box, LinearProgress, Skeleton } from '@mui/material';
import SingleValueSelectDropdown from '../Tools/Selects/SingleValueSelectDropdown';
import { isArray } from 'lodash';
import axios from 'axios';
import useGetContractsOptions from './hooks/useGetContractsOptions';
import { getOption } from './hooks/const';
import useFocusWithin from './hooks/useFocusWithin';

export type Contract = {
  contract?: string;
  contract_management_code?: string;
  contract_ve: string;
  client: string;
  contract_client: string;
};

type DefaultSingleValue = {
  optionLabel: JSX.Element;
  label: string;
  group: string;
  value: string | undefined;
};

type ContractsDropdownProps = {
  value?: string[] | string;
  onChange: (data: string[] | string | null) => void;
  searchKey?: string;
  isMulti?: boolean;
  endpoint?: string;
  required?: boolean;
  refresh?: boolean;
  selectAll?: (value: boolean) => void;
  allSelected?: boolean;
  contractsData?: Contract[];
  disabled?: boolean;
};

const ContractsDropdown = ({
  onChange,
  value,
  isMulti = true,
  endpoint,
  required,
  searchKey,
  refresh,
  selectAll,
  allSelected,
  contractsData,
  disabled
}: ContractsDropdownProps) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isMounted, setIsMounted] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [defaultSingleValue, setDefaultSingleValue] = useState<DefaultSingleValue | null>(null);
  const [defaultMultiValue, setDefaultMultiValue] = useState<DefaultSingleValue[]>([]);

  const { ref, isFocused } = useFocusWithin();

  const baseUrl = `/api/boi/params/contracts/`;

  useEffect(() => {
    const getUrl = () => {
      let url = endpoint || `${baseUrl}?size=${defaultSize}`;
      if (searchKey) {
        search.length > 2 && setIsSearchLoading(true);
        url = search.length > 2 ? (endpoint || baseUrl) + '?' + searchKey + '=' + search : '';
      }
      return url;
    };
    const url = getUrl();
    if (contractsData && contractsData.length > 0) {
      setContracts(contractsData);
    } else setIsMounted(true);
    if (url) {
      axios.get(url).then((response) => {
        const contracts =
          ('results' in response.data ? response.data.results : response.data) || [];
        const fromContractsData = contractsData
          ? (contractsData
              ?.map((el) => {
                const isAlreadyInContracts = contracts.find(
                  (contract: Contract) =>
                    contract.contract_management_code === el.contract_management_code
                );
                return isAlreadyInContracts ? null : el;
              })
              .filter(Boolean) as Contract[])
          : [];
        setContracts([...contracts, ...fromContractsData]);
        setIsSearchLoading(false);
      });
    }
  }, [endpoint, searchKey, search, baseUrl, contractsData]);

  useEffect(() => {
    if (value) {
      if (contracts.length > 0) {
        if (!isMulti) {
          const contract = contracts?.find((el) => el.contract_management_code === value);
          if (typeof value === 'string' && contract !== undefined) {
            setDefaultSingleValue(getOption(contract as Contract));
            setIsMounted(true);
          }
        } else {
          if (isArray(value)) {
            const defaultValue = value
              ?.map((contractCode: string) => {
                const contract = contracts?.find(
                  (contract) => contract.contract_management_code === contractCode
                );
                return contract && getOption(contract);
              })
              .filter((el): el is NonNullable<typeof el> => el !== undefined);

            setDefaultMultiValue(defaultValue as DefaultSingleValue[]);
            setIsMounted(true);
          }
        }
      }
    } else contracts.length > 0 && setIsMounted(true);
  }, [value, contracts, isMulti, baseUrl]);

  const clear = () => {
    setDefaultSingleValue(null);
    setDefaultMultiValue([]);
    setContracts([]);
    setSearch('');
    setIsMounted(false);
  };

  useEffect(() => {
    if (refresh === true) {
      clear();
    }
  }, [refresh]);

  const options = useGetContractsOptions(contracts);

  return (
    <Box sx={{ minHeight: '40px' }} ref={ref}>
      {!isMounted ? (
        <Skeleton variant="rounded" width={'100%'} height={40} />
      ) : (
        <>
          {isMulti ? (
            <MultiValuesSelectDropdown
              defaultValue={defaultMultiValue}
              onChange={(data) => onChange(data?.map((contract) => contract.value as string))}
              options={options}
              onSearch={setSearch}
              searchable={searchKey !== undefined}
              required={required}
              selectAll={selectAll}
              defaultAll={allSelected ?? false}
            />
          ) : (
            <SingleValueSelectDropdown
              required={required}
              options={options}
              onChange={(data) => onChange(data ? (data.value as string) : null)}
              defaultValue={defaultSingleValue}
              onSearch={setSearch}
              searchable={searchKey !== undefined}
              disabled={disabled}
            />
          )}
        </>
      )}
      {isSearchLoading && isFocused && (
        <LinearProgress sx={{ height: 3, marginTop: '-3px', borderRadius: '0 0 4px 4px' }} />
      )}
    </Box>
  );
};

export default ContractsDropdown;
