import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { TourDevice, TourRoute } from '../types';
import useInitialMapState from './hooks/useInitialMapState';
import { MapDevices, MapDrawHandler, MapHelper, MapRoute } from './components/TourMapComponents';

import 'leaflet/dist/leaflet.css';
import './TourMap.scss';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import EmptyPageWithLoader from '../../../../../Tools/Pages/EmptyPageWIthLoader';
import DotsLoader from '../../../../../Tools/DotsLoader/DotsLoader';
import { getDevicesWithCoords } from './const';
import { Alert } from '@mui/material';

const TourMap = ({
  route,
  onSelected,
  showDevices = true,
  model
}: {
  route?: TourRoute[];
  onSelected?: (value: TourDevice[]) => void;
  showDevices?: boolean;
  model: string;
}) => {
  const { dataRefs, loadingStatus } = useFetchDataRefsContext();
  const devices = ((dataRefs[model]?.results as TourDevice[]) || []).map((device) =>
    'data' in device
      ? {
          ...device,
          ...device.data,
          MODULE: device.MODULE || device.device_id,
          device_id: device.MODULE || device.device_id
        }
      : device
  );
  const devicesWithCoords = getDevicesWithCoords(devices);
  const { center, bounds } = useInitialMapState(devices, route);

  return loadingStatus.key === 'tour_devices' && loadingStatus.isLoading ? (
    <EmptyPageWithLoader message={<DotsLoader message="chargement de la map" />} hideSpinner />
  ) : (
    <>
      {route?.length &&
        route[0].latitude.toFixed(4) === route[route.length - 1].latitude.toFixed(4) &&
        route[0].longitude.toFixed(4) === route[route.length - 1].longitude.toFixed(4) && (
          <Alert severity={'info'} sx={{ m: 1 }}>
            Les points de départ et d'arrivée sont très proches géographiquement.
          </Alert>
        )}
      <MapContainer center={center} style={{ height: '550px' }} scrollWheelZoom={false}>
        <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/">CARTO</a>'
        />

        <MapHelper bounds={bounds} />
        <MapDrawHandler devices={devicesWithCoords} onSelected={onSelected} />
        <MapRoute route={route} />
        <MapDevices devices={devicesWithCoords} showDevices={showDevices} />
      </MapContainer>
    </>
  );
};

export default TourMap;
